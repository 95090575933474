<template>
  <v-row justify="center">
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </v-row>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>
